import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, Container} from '@material-ui/core';

import Header from '../header';
import {messages as M} from '../../messages';
import './style.scss';

import SEO from "../../../components/seo"

const Section = ({subTitle, content}) => {
    return (
        <Box className="section">
            <Typography variant="subtitle1" className="sub-title" color="secondary" gutterBottom>
                {subTitle}
            </Typography>
            {content}
        </Box>
    );
};

Section.propTypes = {
    subTitle: PropTypes.any,
    content: PropTypes.any
};

const Content = ({content}) => {
    return (
        <Typography variant="body1" color="secondary" className="content" gutterBottom>
            {content}
        </Typography>
    );
};

Content.propTypes = {
    content: PropTypes.any
};

function TermsAndConditions() {
    return (
        <Container className="container terms-and-conditions">
            <SEO title={M.get('siteMetaData.conditions.title')} description={M.get('siteMetaData.conditions.description')} />
            <Header />
            <Typography variant="subtitle1" className="terms-and-conditions-title" color="primary">
                {M.get('termsAndConditions.title')}
            </Typography>
            <Section
                subTitle={M.get('termsAndConditions.agreement.title')}
                content={
                    <React.Fragment>
                        <Content content={M.get('termsAndConditions.agreement.description1')} />
                        <Content content={M.get('termsAndConditions.agreement.description2')} />
                        <Content content={M.get('termsAndConditions.agreement.description3')} />
                    </React.Fragment>
                }
            />
            <Section
                subTitle={M.get('termsAndConditions.privacy.title')}
                content={<Content content={M.get('termsAndConditions.privacy.description1')} />}
            />
            <Section
                subTitle={M.get('termsAndConditions.electronicCommunications.title')}
                content={<Content content={M.get('termsAndConditions.electronicCommunications.description1')} />}
            />
            <Section
                subTitle={M.get('termsAndConditions.yourAccount.title')}
                content={<Content content={M.get('termsAndConditions.yourAccount.description1')} />}
            />
            <Section
                subTitle={M.get('termsAndConditions.childrenUnderThirteen.title')}
                content={<Content content={M.get('termsAndConditions.childrenUnderThirteen.description1')} />}
            />
            <Section
                subTitle={M.get('termsAndConditions.links.title')}
                content={<Content content={M.get('termsAndConditions.links.description1')} />}
            />
        </Container>
    );
}

export default TermsAndConditions;