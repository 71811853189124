import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"

import TermsAndConditions from "../views/view/terms-and-conditions/index"

const ConditionsPage = () => (
    <TermsAndConditions />
)

export default ConditionsPage